// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsShareofwalletMutationRequest,
  PostV1ResultsShareofwalletMutationResponse,
  PostV1ResultsShareofwalletHeaderParams,
  PostV1ResultsShareofwallet401,
  PostV1ResultsShareofwallet403,
} from '../models/PostV1ResultsShareofwallet'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsShareofwalletClient = typeof client<
  PostV1ResultsShareofwalletMutationResponse,
  PostV1ResultsShareofwallet401 | PostV1ResultsShareofwallet403,
  PostV1ResultsShareofwalletMutationRequest
>
type PostV1ResultsShareofwallet = {
  data: PostV1ResultsShareofwalletMutationResponse
  error: PostV1ResultsShareofwallet401 | PostV1ResultsShareofwallet403
  request: PostV1ResultsShareofwalletMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsShareofwalletHeaderParams
  response: Awaited<ReturnType<PostV1ResultsShareofwalletClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsShareofwalletClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsShareofwalletClient>>
  }
}
export const postV1ResultsShareofwalletQueryKey = (
  data: PostV1ResultsShareofwallet['request'],
) => [{ url: '/v1/results/shareofwallet' }, ...(data ? [data] : [])] as const
export type PostV1ResultsShareofwalletQueryKey = ReturnType<
  typeof postV1ResultsShareofwalletQueryKey
>
export function postV1ResultsShareofwalletQueryOptions(
  data: PostV1ResultsShareofwallet['request'],
  headers?: PostV1ResultsShareofwallet['headerParams'],
  options: PostV1ResultsShareofwallet['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsShareofwalletQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsShareofwallet['data'],
        PostV1ResultsShareofwallet['error']
      >({
        method: 'post',
        url: `/v1/results/shareofwallet`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/shareofwallet
 */
export function usePostV1ResultsShareofwallet<
  TData = PostV1ResultsShareofwallet['response'],
  TQueryData = PostV1ResultsShareofwallet['response'],
  TQueryKey extends QueryKey = PostV1ResultsShareofwalletQueryKey,
>(
  data: PostV1ResultsShareofwallet['request'],
  headers?: PostV1ResultsShareofwallet['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsShareofwallet['response'],
        PostV1ResultsShareofwallet['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsShareofwallet['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsShareofwallet['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsShareofwalletQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsShareofwalletQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsShareofwallet['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsShareofwalletSuspenseQueryKey = (
  data: PostV1ResultsShareofwallet['request'],
) => [{ url: '/v1/results/shareofwallet' }, ...(data ? [data] : [])] as const
export type PostV1ResultsShareofwalletSuspenseQueryKey = ReturnType<
  typeof postV1ResultsShareofwalletSuspenseQueryKey
>
export function postV1ResultsShareofwalletSuspenseQueryOptions(
  data: PostV1ResultsShareofwallet['request'],
  headers?: PostV1ResultsShareofwallet['headerParams'],
  options: PostV1ResultsShareofwallet['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsShareofwalletSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsShareofwallet['data'],
        PostV1ResultsShareofwallet['error']
      >({
        method: 'post',
        url: `/v1/results/shareofwallet`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/shareofwallet
 */
export function usePostV1ResultsShareofwalletSuspense<
  TData = PostV1ResultsShareofwallet['response'],
  TQueryKey extends QueryKey = PostV1ResultsShareofwalletSuspenseQueryKey,
>(
  data: PostV1ResultsShareofwallet['request'],
  headers?: PostV1ResultsShareofwallet['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsShareofwallet['response'],
        PostV1ResultsShareofwallet['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsShareofwallet['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsShareofwallet['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsShareofwalletSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsShareofwalletSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultsShareofwallet['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
