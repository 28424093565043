export enum AccountRoleEnum {
  Analyst = 'analyst',
  Manager = 'manager',
  Owner = 'owner',
  SurveyManager = 'survey-manager',
}

export enum AccountRightEnum {
  AccountSettingsAccountDelete = 'account-settings.account.delete',
  AccountSettingsProfileWrite = 'account-settings.profile.write',
  AccountSettingsRead = 'account-settings.read',
  BiBookingsRead = 'bi.bookings.read',
  BiFinanceRead = 'bi.finance.read',
  BillingWrite = 'billing.write',
  BiMembersRead = 'bi.members.read',
  BiSettingsWrite = 'bi.settings.write',
  GeneralExitRead = 'general.exit.read',
  GeneralHelpCenterRead = 'general.help-center.read',
  SurveySettingsWrite = 'survey-settings.write',
  TeamSettingsUserDelete = 'team-settings.user.delete',
  TeamSettingsUserInvite = 'team-settings.user.invite',
  TeamSettingsUserRoleWrite = 'team-settings.user.role.write',
  UserProfileWrite = 'user-profile.write',
}

export enum CommentAmbassadorTypeEnum {
  Promoters = 'promoters',
  Passives = 'passives',
  Detractors = 'detractors',
}

export enum CommentConsentTypeEnum {
  Consent = 'consent',
  NoConsent = 'no_consent',
}

export enum DatePresetEnum {
  Custom = 'custom',
  Last3Years = 'L3Y',
  Last30Days = 'L30D',
  Last365Days = 'L365D',
  Last90Days = 'L90D',
  Last12Months = 'L12M',
  LastMonth = 'LM',
  LastYear = 'LY',
  ThisMonth = 'TM',
  ThisYear = 'TY',
  ThisYearToDate = 'TYTD',
  Y2018 = 'Y2018',
  Y2019 = 'Y2019',
}

export enum SectionTabEnum {
  Development = 'development',
  Distribution = 'distribution',
  Comments = 'comments',
  ListView = 'listView',
  OtherOptionComments = 'otherOptionComments',
  PriorityMap = 'priorityMap',
  Ranking = 'ranking',
  ShareOfWallet = 'shareOfWallet',
}

export enum VariableTypeEnum {
  Nps = 'nps',
  OtherComment = 'other_comment',
  OtherDistribution = 'other_distribution',
  OtherNPS = 'other_nps',
  OtherOptionComment = 'other_option_comment',
  OtherTouchPoint = 'other_touch_point',
  ServiceArea = 'service_area',
  ShareOfWallet = 'share_of_wallet',
  TouchPoint = 'touch_point',
}

export enum FilterTabEnum {
  Score = 'score',
  Distribution = 'distribution',
}

export enum DistributionTabEnum {
  Percent = 'percent',
  Value = 'value',
}

export enum PriorityCardTabEnum {
  ServiceAreas = 'serviceAreas',
  TouchPoints = 'touchPoints',
}

export const priorityCardTabs = {
  serviceAreas: 'serviceAreas',
  touchPoints: 'touchPoints',
} as const

type PriorityCardTabKeys = keyof typeof priorityCardTabs

export type PriorityCardTab = (typeof priorityCardTabs)[PriorityCardTabKeys]

export type SurveyType = 'bookings' | 'members'
